import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-international-survey',
  templateUrl: './international-survey.component.html',
  styleUrls: ['./international-survey.component.scss']
})
export class InternationalSurveyComponent implements OnInit {

  public formSrc: SafeResourceUrl = '';

  public answering: boolean = false;

  constructor(public route: ActivatedRoute,
              private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  answer() {
    this.answering = true;
    this.formSrc = this.domSanitizer.bypassSecurityTrustResourceUrl('https://forms.office.com/Pages/ResponsePage.aspx?id=xYQXFMt3k0-DEgyIeVLI49VWA9joxShDkBfhlSKc9VhUQ1dSWUFTNlpPSEZHTzRWVlZJN0haWVYwSC4u&embed=true');
  }

}
