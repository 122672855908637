import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InternationalSurveyComponent } from './international-survey/international-survey.component';

// Theme & Settings
import { fengTheme } from '../theme';
import { fengSettings } from '../settings';
import { fengEnv } from '../env';

// FengST
import { NgxFengSTUiModule } from '@fengbrasil/ngx-fengst-ui';
import { NgxFengSTLayoutModule } from '@fengbrasil/ngx-fengst-layout';
import { NgxFengSTContactModule } from '@fengbrasil/ngx-fengst-contact';
import { JwtInterceptor, NgxFengSTAuthModule } from '@fengbrasil/ngx-fengst-auth';
import { NgxFengstExclusiveContentModule } from '@fengbrasil/ngx-fengst-exclusive-content';
import { NgxFengSTXRayModule } from '@fengbrasil/ngx-fengst-xray';
import { NgxFengSTPartnerModule } from '@fengbrasil/ngx-fengst-partner';
import { NgxFengSTCheckoutModule } from '@fengbrasil/ngx-fengst-checkout';
import { NgxFengstEventsModule } from '@fengbrasil/ngx-fengst-events';
import { NgxFengSTExperienceModule } from '@fengbrasil/ngx-fengst-experience';


@NgModule({
  declarations: [
    AppComponent,
    InternationalSurveyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,

    NgxFengSTUiModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTAuthModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTLayoutModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTCheckoutModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengstEventsModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengstExclusiveContentModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTContactModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTPartnerModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTExperienceModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv}),
    NgxFengSTXRayModule.forRoot({'theme': fengTheme, 'settings': fengSettings, 'env': fengEnv})
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: fengEnv.recaptcha.key
    },
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: JwtInterceptor, 
      multi: true 
    },
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
