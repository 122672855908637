export const fengTheme = {
    name: 'feng',
    properties: {
        '--background': '#F8F8F8',
        '--on-background': '#1E3D9F',
        '--primary': '#1E3D8F',
        '--on-primary': '#F8F8F8',
        '--secondary': '#E6AE00',
        '--on-secondary': '#414141',
        '--secondary-light': '#FECC39',
        '--surface': '#F8F8F8',
        '--on-surface': '#11224F',
        '--error': '#F25455',
        '--on-error': '#fff',
        '--warning': '#EFC845',
        '--on-warning': '#fff',
        '--success': '#68DB6C',
        '--on-success': '#fff',
        '--info': '#D0D0D0',
        '--on-info': '#fff',
        '--primary-RGB': '65, 65, 65',
        '--primary-light': '#F8F8F8',
        '--primary-dark': '#1B1B1B',
        '--secondary-dark': '#997308',
        '--secondary-RGB': '191,146,64',
        '--surface-RGB': '0, 0, 0',
        '--error-RGB': '255, 255, 255',
        '--warning-RGB': '255, 255, 255',
        '--success-RGB': '104,219,108',
        '--info-RGB': '255, 255, 255',
        '--on-background-RGB': '25,25,112',

        '--max-width': '1200px',

        // Add external fonts @font-face to styles.scss
        '--font-primary': 'Heebo',
        '--font-title': 'ApexMk3',
        '--font-button': 'Heebo',

        // Menu
        '--menu-itens-color': '--primary-light',
        '--menu-itens-background': 'transparent',
        '--menu-active-color': 'var(--primary)',
        '--menu-active-background': 'var(--secondary)',
        '--menu-active-underline': '0',

        '--menu-mobile-background': '#fff',
        '--menu-mobile-user-detail-color': '#404040',
        '--menu-mobile-user-detail-background': '#fff',
        '--menu-mobile-itens-color': '#404040',
        '--menu-mobile-itens-background': '#fff',

        // Header
        '--header-margin': '',
        '--header-bg': 'url(https://s3.amazonaws.com/socio5estrelas.com.br/Imagens/menu-start-header.png) center no-repeat',
        '--header-bottom-landing': '#CBB374',
        '--header-mobile-bg': 'var(--primary)',
        '--header-mobile-logo-width': '88px',
        '--header-desktop-logo-width': '150px',
        '--header-desktop-shield-width': '61px',
        '--header-mobile-shield-width': '30px',
        // '--header-mobile-height': '130px', // when counter is enabled
        // '--header-desktop-height': '234px', // when counter is enabled
        // '--header-desktop-content-padding': '180px', // when counter is enabled
        // '--header-mobile-content-padding': '34px', // when counter is enabled
        '--header-mobile-height': '30px',
        '--header-desktop-height': '35px',
        '--header-desktop-content-padding': '50px',
        '--header-mobile-content-padding': '30px',
        '--header-mobile-font-size': '14px',
        '--header-desktop-font-size': '18px',
        '--header-mobile-margin-top': '0',
        '--header-desktop-margin-top': '0',
        '--header-mobile-logo-top': '-20px',
        '--header-desktop-logo-top': '-20px',
        '--public-header-security-top-padding': '0',
        '--private-header-security-top-padding': '0',
        '--header-logged-bg': 'linear-gradient(360deg, #010455 0%, rgba(1, 4, 85, 0) 100%), url(https://cruzeiro.s3.amazonaws.com/prod/assets/images/ceu-estrelado-header.png) no-repeat center center/cover',
        '--header-logged-mobile-logo-width': '100px',
        '--header-logged-desktop-logo-width': '240px',

        '--header-counter-background': 'var(--info)',
        '--header-counter-font-family': 'Tektur',
        '--header-counter-font-size': '28px',
        '--header-counter-text-color': 'var(--primary)',
        '--header-counter-color': '#fff',
        '--header-counter-number-bg': 'var(--info)',
        '--header-counter-number-border-color': 'var(--info)',
        '--header-counter-number-outline-color': 'var(--secondary-dark)',
        '--header-counter-number-font-family': 'Tektur',
        '--header-counter-number-font-size': '35px',
        '--header-font-color': '#fff',
        '--header-logged-border-top': 'solid 1px #1b2a6f',
        '--header-desktop-menu-font-size': '15px',

        // Home Public
        // Call to Action
        '--home-call-to-action-font-family': 'Phudu',
        '--home-call-to-action-background': 'no-repeat center right / cover',
        '--home-call-to-action-background-img': 'rgb(0, 7, 128) url(https://cruzeiro.s3.amazonaws.com/prod/assets/images/bg-home-deslogada.png) center / contain no-repeat',
        '--home-call-to-action-detail-color': 'var(--secondary)',
        '--home-call-to-action-title-font-size': '113px',
        '--home-call-to-action-title-font-family': 'Phudu',
        '--home-call-to-action-title-font-weight': 'normal',
        '--home-call-to-action-title-margin': '0 auto 54px',
        '--home-call-to-action-title-line-height': '100%',
        '--home-call-to-action-title-alternate-font-family': 'ApexMk3',
        '--home-call-to-action-title-alternate-font-size': '110px',
        '--home-call-to-action-subtitle-font-size': '30px',
        '--home-call-to-action-subtitle-width': '590px',
        '--home-call-to-action-subtitle-line-height': '140%',
        '--home-call-to-action-content-padding': '2% 0',
        '--home-call-to-action-content-align': 'center',
        '--home-call-to-action-button-margin': '0',
        '--home-call-to-action-button-background': '#EFB701',
        '--home-call-to-action-button-padding': '8px 30px',
        '--home-call-to-action-button-font-size': '29px',
        '--home-call-to-action-button-style': 'normal',
        '--home-call-to-action-button-color': 'var(--primary)',
        '--home-call-to-action-hover-button-color': 'white',
        '--home-call-to-action-title-color': 'var(--secondary)',
        '--home-call-to-action-title-desktop-max-width': '920px',
        '--mobile-home-call-to-action-title-margin': '0 auto 30px',
        '--mobile-home-call-to-action-content-padding': '0 10% 50px',
        '--mobile-home-call-to-action-title-font-size': '40px',
        '--mobile-home-call-to-action-title-line-height': '100%',
        '--mobile-home-call-to-action-title-alternate-font-family': 'ApexMk3',
        '--mobile-home-call-to-action-title-alternate-font-size': '62px',
        '--call-to-action-margin-top': '0',
        '--call-to-action-padding-top': '30px',

        // Tabs
        '--home-desktop-tabs-1-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-1-background-img': '#F8F8F8',
        '--home-desktop-tabs-2-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-2-background-img': '#F8F8F8',
        '--home-desktop-tabs-3-background': 'no-repeat center center / cover',
        '--home-desktop-tabs-3-background-img': '#F8F8F8',

        // First Access
        '--first-access-bg': '#1e3d8e',

        // Home Private
        '--home-private-padding-top': '0',
        '--home-private-featured-height-tablet': '248px',
        '--home-private-featured-height-mobile-410': '133px',
        '--home-private-featured-height-mobile-380': '122px',

        // Plans
        '--plans-background': 'linear-gradient(180deg, #11224F 0%, #050926 100%)',

        // Addons card
        '--addons-card-description': '#727272',
        '--addons-card-help': '#393939',

        // My Profile
        '--my-profile-title-color': 'var(--secondary-dark)',
        '--my-profile-name-color': 'var(--secondary-dark)',

        // Contact
        '--contact-background': 'url(https://cruzeiro.s3.amazonaws.com/prod/assets/images/bg-FAQ.png) no-repeat center center / cover',

        // Experiences
        '--experience-how-to-score-icon-filter': '',

        // Exclusive Content
        '--exclusive-content-text': 'var(--primary)',
        '--exclusive-content-background-RGB': '0,0,0',
        '--exclusive-content-background': 'white',

        // X-Ray
        '--x-ray-text-color': '#000',
        '--x-ray-call-to-action-background': '#000',
        '--x-ray-call-to-action-background-img': 'url(https://cruzeiro.s3.amazonaws.com/prod/assets/images/bg-raio-x.png)',
        '--x-ray-action-active-background-color': '#000',
        '--x-ray-tabgroup-background': 'white',
        '--x-ray-action-not-active-background-color': '#414141',
        '--x-ray-tab-header-top-position': '-30px',
        '--x-ray-tab-header-ink-bar-height': '0',
        '--x-ray-chart-colors': [
            'rgba(160, 169, 195, 1)',
            'rgba(30, 61, 143, 1)',
            'rgba(45, 88, 204, 1)',
            'rgba(149, 149, 149, 1.0)',
            'rgba(160, 169, 195, 1)',
            'rgba(30, 61, 143, 1)',
            'rgba(45, 88, 204, 1)',
            'rgba(149, 149, 149, 1.0)',
            'rgba(160, 169, 195, 1)',
            'rgba(30, 61, 143, 1)',
            'rgba(45, 88, 204, 1)',
            'rgba(149, 149, 149, 1.0)',
        ],

        // Footer
        '--footer-item-margin': '25px auto',
        '--footer-color': 'white',
        '--footer-background': '#000354',
        '--footer-logo-width': '84px',

        // Auth Modal
        '--auth-modal-stlogo-width': '180px',
        '--auth-modal-stlogo-bg': '#1E3D8F',
        '--auth-modal-closebtn-color': 'black',
        '--mobile-auth-modal-closebtn-color': 'white',
        '--mobile-auth-modal-stlogo-width': '100px',
        '--mobile-auth-modal-stlogo-margin': '10px 0',

        // Rating
        '--rating-bg-color': '#FAFAFA',
        '--rating-border-color': '#E4E4E4',

        // Pending E-mail
        '--pending-mail-bg': '##F8F8F8',
        '--pending-mail-color': '##1E3D8F',
        '--pending-mail-logo-height': '100px',
        '--pending-mail-logo-bg': '##1E3D8F',

        // Staging
        '--staging-logo-background-color': '#1E3D8F',

        // Landing
        '--landing-video-background': 'url(https://cruzeiro.s3.amazonaws.com/prod/images/landing/caravana/bakcground_scroll_3.png) repeat center',
    },
    images: {
        'stLogo': 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/nova-logo-socio-5-estrelas.svg',
        'stLogoMobile': 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/marca-bg.png',
        'stLogoFooter': 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/marca_socio_5-estrelas.png',
        'stLoggedLogoFooter': 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/logo-estrela.svg',
        'loadingLogo': 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/marca-bg.png',
        'stShield': 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/escudo-cruzeiro.svg',
        'successTextImg': 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/%23fechadocomocruzeiro.png',
        'stNewLogo': 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/nova-logo-socio-5-estrelas.svg',
        '--homePlansBG': 'linear-gradient(180deg, #11224F 0%, #2331AF 100%)',
        '--homeTicketsBG': 'url(https://cruzeiro.s3.amazonaws.com/prod/assets/images/bg-home-deslogada.png)',
        '--homeExclusiveContentBG': 'url(https://cruzeiro.s3.amazonaws.com/prod/assets/images/bg-home-deslogada.png)',
        header: {
            'stShieldHeader': 'https://cruzeiro.s3.amazonaws.com/prod/assets/images/escudo-cruzeiro.svg',
        },
        'mobileCounterImg': 'https://s3.amazonaws.com/socio5estrelas.com.br/Imagens/Cruzeiro+Estrelas+Branco+Mobile.png'
    },
    texts: {
        // Home
        // Call to Action
        // 'callToActionFeatured': '<h2>ESCREVA MAIS PÁGINAS HERÓICAS</h2> <h1>COM O CRUZEIRO!</h1> <h3>ALÉM DE APOIAR O TIME, GARANTA TAMBÉM <span>BENEFÍCIOS  EXCLUSIVOS</span> <br>E APROVEITE A NOSSA REDE DE PARCEIROS.</h3>',

        'callToActionFeaturedImg': '',
        'callToActionButton': 'SEJA SÓCIO 5 ESTRELAS',

        // Auth
        // First Access
        'firstAccessText': 'Identificamos que este é o seu primeiro acesso ao nosso novo sistema. Para continuar, você deve ler e concordar com os termos de uso e com a política de privacidade!',
        // Recover Pass
        'recoverPassText': 'Preencha os campos abaixo para definir a sua nova senha.',

        //Addons Card
        'addonsCardText': 'ADICIONAIS DO PLANO',
    }
}
