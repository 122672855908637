export const fengEnv = {
  production: false,
  maintenance: false,
  apiURL: 'https://api.stg.cruzeiro.feng.rocks/',
  // apiURL: 'http://127.0.0.1:3333/',
  weURL: 'https://admin.stg.cruzeiro.feng.rocks/',
  recaptcha: {
    key: '6Ldfn14dAAAAAPtXJak4vLWOKcED3WpCK0R4Kfeq'
  },
  konduto: {
    public_key: 'TB3EDE87884'
  },
  auth: {
    new: true,
    social: [
      { name: 'google', provider: '854729594288-ago2rg8f9q7a7fqsolg7ej76tivtqptv.apps.googleusercontent.com' },
      { name: 'facebook', provider: '128491232801140' }
    ],
    cognito: {
      enable: false
    }
  }
};