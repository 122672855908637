<div class="international-survey">

    <div>

        <div class="international-survey--content" *ngIf="!answering; else isAnswering">

            <h1>Vêm aí novidades para nossos torcedores <strong>que moram fora da grande bh.</strong></h1>

            <h2>Para isso, abrimos este canal para escutar a Nação Azul pelo Brasil.</h2>

            <h3>Responda às perguntas até o dia <strong>26/04</strong> para fazer nosso Cruzeiro cada vez mais forte.</h3>

            <p>Sua participação é muito importante.</p>

            <fengstui-button
                [text]="'RESPONDER PESQUISA'"
                [action]="'button'"
                (click)="answer()"
            ></fengstui-button>

        </div>
    
    </div>

     <ng-template #isAnswering>
        <div class="international-survey--iframe">
            <iframe 
                width="100%" 
                height= "600px" 
                [src]= "formSrc" 
                frameborder= "0" 
                marginwidth= "0" 
                marginheight= "0" 
                style= "border: none; max-width:100%; max-height:100vh" 
                allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen>
            </iframe>
        </div>
     </ng-template>

</div>